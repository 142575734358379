<template>
  <div id="header">
    <header class="py-4 px-8 flex flex-col sm:flex-row justify-between items-center max-w-6xl mx-auto">
      <div class="text-center sm:text-left mb-4 sm:mb-0 w-full sm:w-auto">
        <router-link to="/extra/index">
          <img 
            src="\svg\samjcrop.svg" 
            alt="SamJ Logo" 
            class="h-20 w-auto mx-auto sm:mx-0 hover:opacity-90 transition-all duration-300"
          />
        </router-link>
      </div>
    </header>
  </div>
</template>
