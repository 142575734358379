<template>
  <main class="max-w-6xl mx-auto px-4">
    <h2 class="text-3xl mb-4">secrets 🤫</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <component
        v-for="page in extraPages"
        :key="page.url"
        :is="isExternal(page.url) ? 'a' : 'router-link'"
        :to="!isExternal(page.url) ? page.url : null"
        :href="isExternal(page.url) ? page.url : null"
        class="block bg-neutral-950 text-white p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300"
        :target="isExternal(page.url) ? '_blank' : null"
        :rel="isExternal(page.url) ? 'noopener noreferrer' : null"
      >
        <h3 class="text-xl font-semibold mb-2">{{ page.title }}</h3>
        <p class="text-sm">{{ page.desc }}</p>
      </component>
    </div>
  </main>
</template>

<script>
  export default {
    data() {
      return {
        extraPages: [
          { url: "/extra/aboutblank", title: "About Blank", desc: "About blank embedder tool" },
          { url: "/extra/browserinfo", title: "Browser info", desc: "Hackers telling me my info after I forgot it:" },
          { url: "/extra/urlopen", title: "URL Direct", desc: "Page opener tool (useful for kiosk exploits)" },
          { url: "/", title: "more coming soon...", desc: "click here to go home" },
        ],
      };
    },
    methods: {
      isExternal(url) {
        return /^(http|https):/.test(url);
      },
    },
  };
</script>
